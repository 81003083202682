@import './node_modules/bootstrap/scss/bootstrap-grid.scss';
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define font
$custom-typography: mat-typography-config(
  $font-family: 'Quicksand',
);

@include angular-material-typography($custom-typography);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$communicare-primary: mat-palette($mat-purple, 900);
$communicare-accent: mat-palette($mat-blue, 400);

// The warn palette is optional (defaults to red).
$communicare-warn: mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$communicare-theme: mat-light-theme(
  $communicare-primary,
  $communicare-accent,
  $communicare-warn
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($communicare-theme);

// Override default styles for material components
mat-form-field {
  width: 100%;
}

mat-card {
  box-shadow: 0 10px 20px 0 rgba(123, 104, 238, 0.25);
}

.mat-fab {
  background-color: #7b68ee !important;

  .mat-icon {
    color: #fff;
  }
}
