.btn {
  border-radius: 3px;
  line-height: 35px;
  padding: $space-1 12px;
  font-size: 15px;
  font-weight: 300;
  text-decoration: none;
  cursor: pointer;
  transition: 0.2s background-color ease, 0.2s border-color ease,
    0.2s color ease;

  &-light {
    opacity: 0.7;
  }

  &-small {
    font-size: 14px;
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }

  &-large {
    padding: 10px 60px !important;
    border-radius: 50px !important;

    span {
      font-size: 19px;
      font-weight: bold;
    }
  }

  &-primary {
    color: #fff;
    background-color: $primary-color;
    border: 1px solid $primary-color;

    &:hover {
      color: #fff;
      background-color: darken($primary-color, 10%);
    }

    &-lined {
      background-color: transparent;
      color: $primary-color;
      border-color: 1px solid $primary-color;

      &:hover {
        border-color: darken($primary-color, 10%);
      }
    }
  }

  &-secondary {
    color: #fff;
    background-color: $secondary-color !important;
    border: 1px solid $secondary-color !important;

    &:hover {
      color: #fff;
      background-color: darken($secondary-color, 10%);
    }

    &-lined {
      background-color: transparent;
      color: $secondary-color;
      border-color: 1px solid $secondary-color;

      &:hover {
        border-color: darken($secondary-color, 10%);
      }
    }
  }

  &-cancel {
    opacity: .7;
    background-color: $red;
    color: white;
  }

  &-red {
    background-color: $red;
    color: white;
  }

  &-green {
    background-color: $green;
    color: white;
  }

  &-tertiary {
    color: #fff;
    background-color: $tertiary-color !important;
    border: 1px solid $tertiary-color !important;

    &:hover {
      color: #fff;
      background-color: darken($tertiary-color, 10%);
    }

    &-lined {
      background-color: transparent;
      color: $tertiary-color;
      border-color: 1px solid $tertiary-color;

      &:hover {
        border-color: darken($tertiary-color, 10%);
      }
    }
  }

  &-amorSaudePrimary {
    color: #fff;
    background-color: $amorsaude-primary-color;
    border: 1px solid $amorsaude-primary-color;

    &:hover {
      color: #fff;
      background-color: darken($amorsaude-primary-color, 10%);
    }

    &-lined {
      background-color: transparent;
      color: $amorsaude-primary-color;
      border-color: 1px solid $amorsaude-primary-color;

      &:hover {
        border-color: darken($amorsaude-primary-color, 10%);
      }
    }
  }

  &-amorSaudeSecondary {
    color: #fff;
    background-color: $amorsaude-secondary-color !important;
    border: 1px solid $amorsaude-secondary-color !important;

    &:hover {
      color: #fff;
      background-color: darken($amorsaude-secondary-color, 10%);
    }

    &-lined {
      background-color: transparent;
      color: $amorsaude-secondary-color;
      border-color: 1px solid $amorsaude-secondary-color;

      &:hover {
        border-color: darken($amorsaude-secondary-color, 10%);
      }
    }
  }

  &-amorSaudeTertiary {
    color: #fff;
    background-color: $amorsaude-tertiary-color !important;
    border: 1px solid $amorsaude-tertiary-color !important;

    &:hover {
      color: #fff;
      background-color: darken($amorsaude-tertiary-color, 10%);
    }

    &-lined {
      background-color: transparent;
      color: $amorsaude-tertiary-color;
      border-color: 1px solid $amorsaude-tertiary-color;

      &:hover {
        border-color: darken($amorsaude-tertiary-color, 10%);
      }
    }
  }

  &-danger {
    color: #fff;
    background-color: $color-danger;
    border: 1px solid $color-danger;

    &:hover {
      color: #fff;
      background-color: darken($color-danger, 10%);
    }

    &-lined {
      background-color: transparent;
      color: $color-danger;
      border-color: 1px solid $color-danger;

      &:hover {
        border-color: darken($color-danger, 10%);
      }
    }
  }
}
