// Generate property classes
// ie:
//  .p-padding-left-2 {
//      padding-left: 16px; // (2 * $space-1). $space-1 is a `spacements.scss` variable, that represents `8px`
//  }
//
// On html:
// <div class="p-padding-left-2">  <<<== Inserts a 16px padding on element

$properties: 'margin', 'padding';
$directions: 'top', 'right', 'bottom', 'left';

.p-display-flex {
  display: flex !important;
}

.p-display-block {
  display: block !important;
}

@for $i from 0 through 3 {
  @each $property in $properties {
    .p-#{$property}-#{$i} {
      #{$property}: #{$i * $space-1} !important;
    }

    @each $direction in $directions {
      .p-#{$property}-#{$direction}-#{$i} {
        #{$property}-#{$direction}: #{$i * $space-1} !important;
      }
    }

    .p-#{$property}-horizontal-#{$i} {
      #{$property}-left: #{$i * $space-1} !important;
      #{$property}-right: #{$i * $space-1} !important;
    }

    .p-#{$property}-vertical-#{$i} {
      #{$property}-top: #{$i * $space-1} !important;
      #{$property}-bottom: #{$i * $space-1} !important;
    }
  }
}
