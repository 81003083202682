// Standards for spacements

$space: 8px;
$space-1: $space;
$space-2: $space * 2;
$space-3: $space * 3;
$space-4: $space * 4;
$space-5: $space * 5;
$space-6: $space * 6;
$space-7: $space * 7;
$space-8: $space * 8;
$space-9: $space * 9;
$space-10: $space * 10;