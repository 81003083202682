@import './app/shared/scss/ckeditor.scss';
@import './app/shared/scss/core.scss';
@import './app/shared/scss/material-config.scss';
@import './app/shared/scss/buttons.scss';
@import './app/shared/scss/properties.scss';

body {
  background-color: $bgColor;
  margin: 0;
  font-family: 'Quicksand', sans-serif;
}

.wrapper {
  min-height: auto !important;
}

.wrapper-body-lock {
  min-height: 110vh !important;
}

div,
p {
  font-family: 'Quicksand', sans-serif;
}

.mainCard {
  flex: 1;
  background-color: #fff;
  box-shadow: 0 0 20px -6px rgba(0, 0, 0, 0.1) !important;
  border-radius: 5px;
  padding: 20px 20px 34px 20px;

  @media (max-width: $screen-tablet-max) {
    margin-bottom: 140px;
  }
}

.offline-bar~div .mainCard {
  margin-top: 0;
}

.with-shadow {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.rounded {
  border-radius: 3px;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.clearfix {
  flex-basis: 100%;
  height: 0;
}

.main-card-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.spacer {
  flex: 1 1 auto;
}

.fullWidth {
  display: block;
  width: 100%;
}

.signCard {
  margin: $space-4 auto;
  max-width: 440px;

  @media only screen and (max-width: 480px) {
    margin-left: $space-1;
  }
}

.app-container {
  width: 98%;
  margin: 0 auto;
}

.main-container {
  padding-top: 100px;
}

app-avatar {
  border-radius: 50%;
}

.purple {
  color: $purple  !important;
}

.blue {
  color: $blue  !important;
}

.bg-purple {
  background-color: $purple  !important;
}

.amorsaudeColor {
  color: $amorsaude-secondary-color  !important;
}

.amorsaude-bg-color {
  background-color: $amorsaude-secondary-color  !important;
}

.cancel {
  opacity: 0.7;
  margin-right: 10px !important;
}

.visible-tablet-and-above {
  display: none !important;

  @media (min-width: $screen-tablet-min) {
    display: inherit !important;
  }
}

.hidden-tablet-and-above {
  display: block !important;

  @media (min-width: $screen-tablet-min) {
    display: none !important;
  }
}

snack-bar-container.mat-snack-bar-container>simple-snack-bar {
  color: white;
}

snack-bar-container.mat-snack-bar-container {
  background: $purple;
  color: white;
}

.mat-simple-snackbar-action {
  color: white;
}

.action-buttons {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: right;
  gap: 10px;
}

button.mat-button {
  fa-icon {
    margin-right: 5px;
  }
}

.mat-spinner circle {
  stroke: $primary-color;
}

.loading-screen {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 500px;
  min-width: 100%;
}

table{
  width: 100%;
  text-align: center;
  align-items: center;
  margin-top: 20px !important;

  th.mat-header-cell{
    font-size: 12pt;
    font-weight: bolder;
    text-align: center !important;

    color: black;
    background-color: $gray;
  }

  tr.mat-row{
    text-align: center !important;
  }

  tr.mat-row:hover, tr.mat-row:nth-child(even):hover{
    background-color: $tableHoverbackgroundColor;
    
  }

  tr.mat-row:nth-child(even){
    background-color: $tableTrBgColor;  
  }

  button{
    background-color: transparent;
    border: none;
    color: black !important;
    font-size: 20px !important;
  }

}

.mat-tooltip{
  font-size: 10pt !important;
  background-color: rgb(28, 29, 32);
}


.mat-form-field-appearance-outline.mat-form-field-disabled
.mat-form-field-outline {
  color: rgba(0, 0, 0, 0.05) !important;
  background-color: rgba(0, 0, 0, 0.05) !important;
}

.mat-form-field-label {
  color: black !important;
}

.mat-form-field-outline{
  color: $primary-color !important;
}

.actionBtn{
  display: flex;
  justify-content: flex-end;
  margin-right: 15px;
  gap: 10px;
}

app-loading{
  display: flex;
  justify-content: center;
  text-align: center;
  overflow: hidden;
  margin: 10% 0 !important;
}
