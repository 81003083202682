$purple: #7b68ee;
$orange: #fc7917;
$blue: #008db3;
$green: #8FD14F;
$darkblue: #404AB1;
$red: #e15241;
$gray: #CCCCCC;
$darkgray: #a5a3a3;

$amorsaudeRed: #d83834;
$amorsaudelight: #d0ecf1;
$amorsaudeBlue: #61c1d0;
$backgroundColor: #effafc;
$tableHoverbackgroundColor: #f2f7f8;
$amorSaudeDarkblue: #448792;


$bgColor: #f3f5f9;
$primary-color: $darkblue;
$secondary-color: $green;
$tertiary-color: $blue;
$color-danger: #b60000;

$tableTrBgColor: $backgroundColor;
$amorsaude-primary-color: $amorSaudeDarkblue;
$amorsaude-secondary-color: $amorsaudeBlue;
$amorsaude-tertiary-color: $amorsaudeRed;
